import classNames from "classnames";
import React from "react";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>Тел: (+992) 90-500-63-50</li>
      </ul>
    </nav>
  );
};

export default FooterNav;
