import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image width={210} src={require("./../../../assets/images/logo-no-background.png")} alt="Open" />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
