import React from "react";
import Logo from "./partials/Logo";

const Header = ({ className, bottomDivider, ...props }) => {
  return (
    <header {...props}>
      <div className="container">
        <div className="site-header-inner">
          <Logo />
        </div>
      </div>
    </header>
  );
};

export default Header;
