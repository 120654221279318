import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import FooterNav from "../layout/partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import Logo from "./partials/Logo";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const date = new Date().getFullYear();

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames("site-footer center-content-mobile", topOuterDivider && "has-top-divider", className);

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div className={classNames("site-footer-inner", topDivider && "has-top-divider")}>
          <div className="footer-top space-between text-xxs">
            <Logo onClick={goToTop} />
            <FooterSocial />
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <FooterNav />
            <span style={{ fontSize: "14px" }}>ул. Бохтар 21а, Душанбе, Таджикистан</span>
          </div>
          <span style={{ fontSize: "14px" }}>{date}© CashService Мы работаем – всё работает!</span>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
