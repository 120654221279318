import React from "react";
// import sections
import FeaturesSplit from "../components/sections/FeaturesSplit";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import Hero from "../components/sections/Hero";
import Testimonial from "../components/sections/Testimonial";

const Home = () => {
  return (
    <>
      <Hero />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill />
      <Testimonial topDivider />
    </>
  );
};

export default Home;
